<script setup lang="ts">
const open = defineModel<boolean>("open")

const emit = defineEmits(["close"])
function close() {
  open.value = false
  emit("close")
}

watch(open, () => {
  if (open.value) {
    scrollbarWidth.value = getBodyScrollbarWidth()
  }
})

/** We have a hidden outer div that always has a scrollbar.
 *  In order to get the width, we subtract the width of the outer element with the width of an inner element
 *  In case the body doesn't have a scrollbar, we return 0 */
function getBodyScrollbarWidth(): number {
  if (!scrollOuter.value || !scrollInner.value) {
    return 0
  }
  const bodyHasScrollbar =
    document.body.scrollHeight > document.documentElement.clientHeight
  if (!bodyHasScrollbar) {
    return 0
  }
  return scrollOuter.value?.offsetWidth - scrollInner.value?.offsetWidth
}

const scrollOuter = ref<HTMLDivElement | null>()
const scrollInner = ref<HTMLDivElement | null>()

const scrollbarWidth = ref(0)

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (open.value) {
        return "overflow-hidden"
      }
      return ""
    }),
    style: computed(() => {
      // In order to avoid page jumping behind modal due to scrollbar disappearing, we have to add padding corresponding to the scrollbar width
      // The scrollbar disappears because we add `overflow-hidden` to body to prevent scrolling
      return open.value ? `padding-right: ${scrollbarWidth.value}px` : ""
    }),
  },
})
onBeforeRouteLeave(async () => {
  if (import.meta.client) {
    // When navigating useHead doesn't seem to update.
    // Instead we manually remove class and style on navigation
    document.body.className = document.body.className.replace(
      "overflow-hidden",
      "",
    )
    document.body.style.paddingRight = ""
  }
})
</script>
<template>
  <Teleport to="body">
    <div ref="scrollOuter" class="invisible overflow-y-scroll">
      <div ref="scrollInner" />
    </div>
    <Transition
      enter-active-class="duration-100 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div
        v-if="open"
        class="fixed top-0 z-50 h-screen w-screen bg-slate-600/75"
        @click="
          (event) => {
            if (event.target != event.currentTarget) {
              // Only close when clicking backdrop, not children
              return
            }
            close()
          }
        "
        @keydown.escape="close"
      >
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>
